.blog-single {
  @include z-index-setup();
  background-color: white;
  padding-top: 70px;

  @include breakpoint(xlarge){
    padding-top: 140px;
  }

  &.padding-bottom {
    padding-bottom: 70px;
  }

  &.no-padding-top {
    padding-top: 0!important;
  }

  &.no-post-thumbnail {
    padding-top: 0;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 30px;
  }

  p,li {
    @include font-family(TheinhardtRegular);
    font-size: 18px;
    line-height: 24px;
    color: color(black);
    letter-spacing: 0;

    @include breakpoint(large){
      font-size: 22px;
      line-height: 32px;
    }

    strong,
    b {
      @include font-family(TheinhardtBold);
      font-weight: normal;
    }

    a {
      color: color(blue);
    }
  }

  p {
    margin-bottom: 2rem;
  }

  ul {
    margin: 20px 0 0 10px;
    padding: 0;
    list-style: disc inside;

    ul {
      list-style: circle inside;
      ul {
        list-style: square inside;
      }
    }

    li {
      margin-bottom: 20px;
    }
  }

  ol {
    margin-bottom: 30px;

    li {
      margin-bottom: 20px;
    }
  }

  img {
    display: block;
    float: none;
    margin: 0 auto;
    margin-bottom: 2rem;

    &.alignleft,
    &.alignright,
    &.aligncenter {
      float: none;
    }

    &.image-float-left {
      float: left;
      margin-right: 1rem;
    }
    &.image-float-right {
      float: right;
      margin-left: 1rem;
    }
  }


  .author-row {
    margin-top: 14px;
    // border-top: 1px solid #D8D8D8;
    margin-bottom: 90px;

    @include breakpoint(large){
      margin-top: 40px;
      border: none;
      margin-bottom: 190px;
    }

    hr {
      border-bottom: 1px solid #D8D8D8;
    }
  }


  .author-box {
    display: block;
    margin-top: 30px;

    &:after {
      content: "";
      display: block;
      clear: both;
    }

    @include breakpoint(medium){
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
      width: 45%;
    }


    .photo {
      display: block;
      float: left;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .info {
      display: block;
      height: 80px;
      float: left;
      padding-left: 20px;

      @include breakpoint(xlarge){
        max-width: 62%;
      }

      .block-content {
        @include full-vertical-alignment();

        .name {
          display: block;
          @include font-family(TheinhardtMedium);
          font-size: 18px;
          line-height: 20px;
          color: color(black);
        }

        .position {
          display: block;
          @include font-family(PlantinMTProLightItalic);
          font-size: 18px;
          line-height: 20px;
          color: color(black);
        }
      }
    }
  }

}
