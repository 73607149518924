.hero {
  height: 510px;
  padding: 0!important;
  // background-color: white;
  @include z-index-setup();
  overflow: hidden;

  @include breakpoint(medium){
    height: 800px;
  }

  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.opacity {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      z-index: 1;
    }

    &.opacity-40 {
      &:before {
        opacity: 0.4;
      }
    }
    &.opacity-70 {
      &:before {
        opacity: 0.7;
      }
    }
  }

  .column,
  .row {
    height: 100%;
  }

  .column {
    position: relative;
  }

  .scroll {
    display: none;
    position: absolute;
    bottom: 60px;
    right: 10px;
    transform: rotate(90deg);
    @include font-family(PlantinMTProLight);
    font-size: 30px;
    line-height: 1;
    color: white;
    letter-spacing: 0;
    z-index: 1;

    @include breakpoint(medium){
      display: block;
    }

    @media (min-width: 1300px){
      right: 28px;
    }
  }

  &.inner-hero {
    height: auto;
    padding: 230px 0px 130px 0px!important;

    .column,
    .row {
      height: auto;
    }

    .block-content {
      top: 0;
      left: 0;
      -moz-transform: none!important;
      -ms-transform: none!important;
      -webkit-transform: none!important;
      transform: none!important;
      margin: 0 auto!important;
    }
  }

  .block-content {
    display: block;
    padding: 0 40px;
    text-align: center;
    z-index: 2;
    @include full-vertical-alignment();

    @include breakpoint(large){
      max-width: 600px;
      body.lang-fr & {
        max-width: 920px;
      }
      padding: 0;
      @include full-center-alignment();
    }

    h1 {
      @include font-family(PlantinMTProLight);
      font-size: 38px;
      line-height: 39px;
      color: color(white);

      @include breakpoint(large){
        font-size: 60px;
        line-height: 62px;
      }

      span {
        @include font-family(TheinhardtBold);
      }
    }

    p {
      @include font-family(TheinhardtRegular);
      font-size: 16px;
      line-height: 22px;
      color: color(black);
      letter-spacing: 0;

      @include breakpoint(large){
        font-size: 24px;
        line-height: 40px;
      }

      span {
        display: block;
        @include font-family(PlantinMTProLightItalic);
        font-size: 18px;
        line-height: 40px;

        @include breakpoint(large){
          font-size: 30px;
          line-height: 60px;
        }
      }
    }
  }

  &.blog {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(large){
      background-attachment: fixed;
    }

    &.detail {
      .block-content {
        p {
          @include font-family(PlantinMTProLightItalic);
        }
      }
    }

    .block-content {
      max-width: 1100px;
      text-align: center;
      padding: 0 20px;
      max-width: 0 auto;

      h1 {
        font-size: 30px;
        line-height: 32px;
        a{
          color: white;
        }

        @include breakpoint(large){
          font-size: 74px;
          line-height: 80px;
        }
      }

      p {
        @include font-family(TheinhardtMedium);
        margin-top: 50px;
        font-size: 19px;

        @include breakpoint(large){
          font-size: 24px;
          margin-top: 30px;
        }

        a {
          color: white;
          border-bottom: 1px solid white;
          padding-bottom: 3px;
        }
      }
    }
  }

}
