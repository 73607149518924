body.op0 {
  opacity: 0;
}

body.scroll-locked {
  max-height: 100vh;
  overflow: hidden;
}

.equity-hero {
  overflow: hidden;
  position: relative;
  z-index: 13;
  padding-top: 160px;
  padding-bottom: 60px;
  background-color: white;

  @include breakpoint(medium){
    padding-top: 150px;
    padding-bottom: 75px;
  }

  @include breakpoint(large){
    padding: 0;
  }

  &.desktop-overflow-visible {
    @include breakpoint(xlarge) {
      overflow: visible;
    }
  }

  .row {
    max-width: 1170px;
  }

  .relative {
    position: relative;
  }

  svg {
    max-width: 100%;
  }

  .banner-landing {
    margin-top: 30px;

    @include breakpoint(large) {
      position: absolute;
      top: 120px;
      right: 0;
      z-index: 1;
      max-width: 860px;
      margin: 0;
    }
  }

  &.landing {
    @include breakpoint(large){
      padding-bottom: 0;
    }

    .equity-hero-headline {
      position: relative;
      z-index: 10;
    }

    #landing-arrows {
      position: absolute;
      z-index: 3;
      top: 260px;
      right: 10%;

      @include breakpoint(medium only) {
        top: 575px;
        right: 20%;
      }
    }

    #landing-globe {
      display: none;
      position: absolute;
      z-index: 3;
      top: 85%;
      left: 35%;

      @include breakpoint(large) {
        display: block;
      }
    }
  }

  .full-height {
    @include breakpoint(large) {
      height: 100vh;
      position: relative;
    }
  }

  .vertical-alignment {
    position: relative;
    z-index: 100;

    @include breakpoint(large) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.systemic {
    //height: 100vh;
  }

  &.opportunities {
    @include breakpoint(small only) {
      padding-top: 100px;
      padding-bottom: 200px;
    }
  }

  &.content {
    @include breakpoint(medium only) {
      padding-top: 250px;
      padding-bottom: 100px;
    }
  }


  &--icon-opportunities {
    position: absolute;
    z-index: 10;
    width: 80px;

    @include breakpoint(large) {
      width: 110px;
      height: 110px;
    }

    svg {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &.icon-1 {
      top: 55%;
      right: 30%;

      @include breakpoint(large) {
        top: 25%;
      }
    }
    &.icon-2 {
      bottom: 15%;
      left: 10%;

      @include breakpoint(large) {
        left: 50%;
      }
    }
    &.icon-3 {
      bottom: 0;
      left: 33%;

      @include breakpoint(large) {
        bottom: 7%;
        left: 75%;
      }
    }
    &.icon-4 {
      top: 68%;
      right: -10px;

      @include breakpoint(large) {
        top: 50%;
      }
    }
    &.icon-5 {
      display: none;
      bottom: 12%;
      left: 3%;

      @include breakpoint(large) {
        display: block;
        left: -10%;
      }
    }
  }

  &--icon-content {
    position: absolute;
    z-index: 0;

    @include breakpoint(large) {
      width: auto;
    }

    svg {
      display: block;
      max-width: 100%;
      height: auto;
    }

    &.icon-globe {
      top: -18%;
      left: -13%;
      @include breakpoint(medium) {
        top: -30%;
        left: -10%;
      }
    }

    &.icon-arrows {
      max-width: 205px;
      bottom: -65px;
      left: 15%;

      @include breakpoint(medium only) {
        bottom: 140px;
        left: 15%;
      }

      @include breakpoint(large) {
        opacity: 1;
        max-width: 100%;
        bottom: 110px;
        left: 18%;
      }
    }

    &.icon-1 {
      opacity: 0.5;
      top: 20px;
      right: -74%;

      @include breakpoint(medium only) {
        top: 20px;
        right: 0;
      }

      @include breakpoint(large) {
        opacity: 1;
        max-width: auto;
        right: 15%;
      }
    }

    &.icon-2 {
      opacity: 0.5;
      top: 70%;
      right: -135px;

      @include breakpoint(medium only) {
        top: 64%;
        right: 20px;
      }

      @include breakpoint(large) {
        opacity: 1;
        max-width: auto;
        top: 50%;
        right: -100px;
      }
    }

    &.icon-3 {
      opacity: 0.5;
      bottom: 40px;
      right: 10%;

      @include breakpoint(medium only) {
        bottom: 30px;
        right: 53%;
      }

      @include breakpoint(large) {
        opacity: 1;
        max-width: auto;

      }
    }

    &.icon-4 {
      opacity: 0.5;
      bottom: -190px;
      left: -10%;

      @include breakpoint(medium only) {
        bottom: 216px;
        left: -10%;
      }

      @include breakpoint(large) {
        opacity: 1;
        max-width: auto;
        bottom: 40px;
      }
    }
  }


  .equity-accessibility {
    position: relative;
    display: block;
    margin-top: 40px;
    max-width: 475px;
    background: rgba(242,245,245,.9);
    padding: 5px 10px;
    @include animation();

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    a {
      display: block;
      height: 100%;
    }

    i {
      float: left;
      width: 39px;
      height: 41px;
      margin-right: 10px;

      svg #shape,
      svg #ellipe {
        @include animation();
      }
    }

    p {
      float: left;
      @include font-family(TheinhardtRegular);
      font-size: 14px;
      line-height: 16px;
      color: color(black);
      margin: 0;
      width: calc(100% - 50px);

      @include breakpoint(large) {
        font-size: 18px;
        line-height: 18px;
      }

      strong {
        @include font-family(TheinhardtMedium);
        font-weight: normal;
      }
    }

    &:hover {
      background: color(black);

      p {
        color: white;
      }

      i svg #shape {
        fill: white;
      }
    }
  }
}

.keep-scrolling {
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  padding: 5px 15px;
  background-color: #F6F8F8;
  @include font-family(TheinhardtRegular);
  font-size: 18px;
  color: color(black);
  border-radius: 40px;

  @include breakpoint(large) {
    display: block;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin-right: 10px;

    svg {
      display: block;
    }
  }
}

.equity-content {
  position: relative;
  z-index: 3;
  padding-bottom: 200px;
  //background-color: white;

  &.no-pb {
    padding-bottom: 0px;
  }

  &#exploring-the-icerberg {
    opacity: 0;
  }

  &.low-z-index {
    z-index: 0;
  }

  &.high-z-index {
    z-index: 100;
  }

  &.ready-to-act {
    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(large) {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }

  &.planning-action {
    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(large) {
      padding-top: 140px;
      padding-bottom: 140px;
    }

    .planning-action-globe {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      z-index: 1;
      width: 100%;

      @include breakpoint(large) {
        display: block;
      }

      svg {
        display: block;
        margin: 0 auto;
        height: auto;
      }
    }
  }

  &.representation-content {
    // padding-top: 50px;
    // padding-bottom: 50px;

    @include breakpoint(large) {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }

  &.calibrating-messages {
    padding-top: 50px;
    padding-bottom: 50px;

    @include breakpoint(large) {
      padding-top: 140px;
      padding-bottom: 140px;
    }

    .ico-calibrating-1,
    .ico-calibrating-2 {
      position: absolute;
      display: none;
      @include breakpoint(xlarge) {
        display: block;
      }
    }

    .ico-calibrating-1 {
      left: 5%;
      top: 20%;
    }

    .ico-calibrating-2 {
      right: 5%;
      bottom: 20%;
    }

    .ico-calibrating-braket-top,
    .ico-calibrating-braket-bottom,
    .ico-calibrating-braket-arrow {
      display: none;
      margin: 0 auto;
      text-align: center;

      svg {
        max-width: 100%;
      }
      @include breakpoint(large) {
        display: block;
      }
    }
    .ico-calibrating-braket-top {
      margin-bottom: -40px;
    }
    .ico-calibrating-braket-bottom {
      margin-top: -70px;
    }
  }

  &.margin-top-negative {
    margin-top: -150px;
    z-index: 9;
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.black {
    background-color: color(black);
  }

  &.grey {
    background-color: #F2F5F5;
  }

  &.white {
    background-color: white;
  }

  &.blue {
    background-color: color(blue-accesible);
  }

  &.black-and-white {
    background-color: white;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80%;
      background-color: color(black);

      @include breakpoint(large) {
        height: 40%;
      }
    }
  }

  &.blue-black {
    background-color: color(blue-accesible);
    padding: 70px 0;

    @include breakpoint(large) {
      padding: 140px 0;
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: color(black);

      @include breakpoint(large) {
        height: 25%;
      }
    }
  }

  &.explore-iceberg {
    margin-bottom: 500px;
    z-index: 10;
  }

  &.iceberg-background {
    background: #1D1E21;

    @include breakpoint(small only) {
      padding-bottom: 100px;
    }


    &.fixed {
      background: #1D1E21;
      position: fixed;
      //top: 100vh;
      bottom: 0;
      width: 100%;
      z-index: 100;
      padding-bottom: 0;

      @include breakpoint(large) {
        bottom: -175px;
      }
    }

    &:after {
      content: "";
      height: 94px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;

      @include breakpoint(large) {
        display: none;
      }
    }
  }

  .row {
    max-width: 1170px;

    &.expanded {
      max-width: none !important;
    }

    &.tile-boxes {
      position: relative;
      margin-top: 60px;
      @include breakpoint(large){
        margin-top: 10px;
      }

      &.newsletter-visible {
        .overlay {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            display: table-cell;
            z-index: 9;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.9);

            @include breakpoint(large) {
              width: calc(100% - 4px);
              left: 4px;
            }
          }

          &.end {
            &:before {
              @include breakpoint(large) {
                width: 100%!important;
                left: 0;
              }
            }
          }


          &.not-desktop {
            @include breakpoint(large) {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }

    .tiles-no-margin {
      .tile-boxes {
        margin-top: 20px;
        @include breakpoint(large) {
          margin: 0!important;
        }
      }
    }

    &.section-3-row {
      padding-top: 50px!important;
    }

    .section-3-row.desktop {
      padding-top: 0;
      margin-top: 125px;
      &.first {
        margin-top: 0;
      }
    }

  }

  p {
    @include font-family(TheinhardtRegular);
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;

    @include breakpoint(medium){
      font-size: 22px;
      line-height: 28px;
    }

    &.small {
      font-size: 18px;
      line-height: 26px;
      color: #2C2D32;

      @include breakpoint(medium){
        font-size: 19px;
        line-height: 22px;
      }
    }
  }

  .icon-phase {
    display: block;
    margin-bottom: 20px;
    width: 70px;
    height: 70px;

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .ico-arrow-quote {
    display: none;
    vertical-align: top;
    margin-right: 30px;
    margin-top: 30px;

    @include breakpoint(large) {
      display: inline-block;
    }
  }
}


// Equity Box
.equity-box {
  position: relative;
  // height: 0;
  // padding-bottom: 100%;
  border-top: 4px solid color(blue-accesible);
  border-left: 4px solid color(blue-accesible);
  border-right: 4px solid color(blue-accesible);
  border-bottom: 4px solid color(blue-accesible);
  overflow: hidden;
  transition: all .3s ease-in-out;
  cursor: pointer;
  background-color: white;
  max-width: 390px;
  height: 390px;
  margin: 0 auto;

  @include breakpoint(medium) {
    margin-bottom: 20px;
  }

  @include breakpoint(large){
    border-right: none;
    margin-bottom: 0;
  }

  &.active {
    background-color: #d9e6fd;
  }

  &.last {
    border-right: 4px solid color(blue-accesible);
  }

  &:hover,
  &.active {
    background-color: color(black);

    .box-headline {
      color: white;
      transform: translateY(50px);
    }

    .box-num {
      color: white;
    }
  }

  .box-tagline {
    position: absolute;
    top: 20px;
    left: 20px;
    @include font-family(TheinhardtMedium);
    font-size: 15px;
    line-height: 26px;
    color: white;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  .box-headline {
    position: absolute;
    z-index: 2;
    width: 70%;
    transform: translateY(20px);
    left: 20px;
    @include font-family(TheinhardtMedium);
    font-size: 36px;
    line-height: 36px;
    color: color(black);
    letter-spacing: -0.5px;
    transition: all .3s ease;
  }

  .box-num {
    position: absolute;
    top: 20px;
    right: 20px;
    @include font-family(TheinhardtMedium);
    font-size: 60px;
    line-height: 1;
    color: color(blue-accesible);
    letter-spacing: -4.24px;
    transition: all .3s ease;
  }

  svg {
    position: absolute;
    z-index: 1;
    transition: all .3s ease-in-out;
  }

  &#box-systemic-story {
    svg {
      bottom: -58%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &#box-opportunities {
    svg#landing-box-02-shape {
      left: 50%;
      transform: translateX(-50%);
    }
    svg#landing-box-02-shape-hover {
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &#box-respectful {
    svg#landing-box-03-shape {
      bottom: 0;
      right: 0;
    }

    svg#landing-box-03-shape-hover {
      bottom: 10px;
      left: 0;
    }
  }
}


// Newsletter
.close-newsletter {
  display: none;
  position: absolute;
  z-index: 12;
  top: -55px;
  right: 20px;
  width: 18px;
  height: 18px;
  background: url('/src/images/ico-close-white.svg') center center no-repeat;
  background-size: cover;

  @include breakpoint(large) {
    top: -55px;
    width: 32px;
    height: 32px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
.equity-newsletter {
  display: none;
  position: absolute;
  z-index: 10;
  top: 75px;
  background-color: white;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media screen and (min-width: 456px) {
    margin: 0 auto;
  }

  @include breakpoint(large) {
    top: 80px;
    width: 66.3%;
    max-width: 100%;
    right: 0;
    left: auto;
    box-shadow: 0 24px 26px 4px rgba(0,0,0,0.26);
  }

  #salesforce-thankyou {
    display: none;

    &.visible {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--content {
    padding: 35px 35px;
    position: relative;

    @include breakpoint(large) {
      padding: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: -16px;
      left: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 16px 16px 16px;
      border-color: transparent transparent #ffffff transparent;
    }

    &:after {
      content: "";
      position: absolute;
      top: -60px;
      left: 25px;
      width: 31px;
      height: 31px;
      background: url('/src/images/ico-edit.svg') center center no-repeat;
      background-size: contain;
    }
  }

  input[type="text"],
  input[type="email"] {
    @include breakpoint(large){
      height: 60px;
      line-height: 60px;
      margin-bottom: 20px;
    }
  }

  &.shortcode {
    width: 100%;
    display: block;
    position: relative;
    top: 0;
    box-shadow: none;

    .equity-newsletter--content {
      padding: 0;
      &:before,
      &:after {
        display: none;
      }

      .btn-submit {
        font-size: 20px;
      }
    }

  }

  .newsletter-headline {
    display: block;
    @include font-family(TheinhardtMedium);
    font-size: 36px;
    line-height: 36px;
    color: color(black);
    letter-spacing: -0.5px;
    margin-bottom: 10px;
  }

  .newsletter-checkbox {
    text-align: left;
    margin-bottom: 20px;
    display: block;

    &.disabled {
      opacity: .6;
      pointer-events: none;
      cursor: default;
    }

    input[type="checkbox"] {
      display:none;
    }

    label {
      margin: 0;
      @include font-family(TheinhardtRegular);
      font-size: 21px;
      line-height: 26px;
      color: color(black);
    }

    input[type="checkbox"] + label {
      margin: 0;
    }

    input[type="checkbox"] + label:before {
      content: "";
      display: inline-block;
      margin: 0 7px 0 0;
      cursor: pointer;
      width: 22px;
      height: 22px;
      float: left;
      background-color: #FFFFFF;
      border: 2px solid rgba(0,0,0,0.10);
      border-radius: 50%;
    }

    input[type="checkbox"]:checked + label:before {
      content: "";
      background-color: color(blue-accesible);
    }
  }

  .btn-submit {
    background-color: color(blue-accesible);
    border: none;
    font-size: 36px;
  }
}

// Accordion
.accordion.equity-frameworks {
  margin-top: 0;

  li.is-active {
    .accordion-title {
      background: white;

      .block-content {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  .accordion-title {
    position: relative;
    background-color: color(grey);
    border: none;
    padding: 0;
    border-bottom: 1px solid rgba(23,30,33,0.4);

    &:before {
      display: none;
    }

    .block-content {
      @include grid-row;
      padding: 1.5625rem;

      @include breakpoint(large){
        padding: 1.875rem;
        position: relative;
      }

      .title {
        display: block;
        @include font-family(TheinhardtBold);
        font-size: 22px;
        line-height: 22px;
        color: color(black);
        letter-spacing: 0;
        // margin-bottom: 10px;
        max-width: 85%;

        @include breakpoint(large){
          font-size: 28px;
          line-height: 42px;
          float: left;
          margin: 0;
        }
      }

      i {
        display: block;
        position: absolute;
        top: 50%;
        right: 1.5625rem;
        margin-top: -6px;
        width: 20px;
        height: 12px;

        @include breakpoint(large){
          right: 0;
        }

        span {
          background: url('/src/images/ico-accordion-arrow-black.svg');
          display: none;
        }

        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }


  .accordion-content {
    border: none;
    padding: 30px 0;

    p {
      @include font-family(TheinhardtRegular);
      font-size: 16px;
      line-height: 22px;
      color: color(black);
      opacity: 0.7;

      @include breakpoint(large){
        font-size: 19px;
        line-height: 28px;
      }
    }
  }
}


// Conversational intro
.img-bracket {
  display: none;
  text-align: center;
  pointer-events: none;

  svg {
    max-width: 100%;
  }

  @include breakpoint(large) {
    display: block;
    position: fixed;
    max-width: 1070px;
    top: calc(100vh - 250px);
    margin: 0 auto;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);

    .bracket-mask {
      background-color: white;
      width: 100%;
      height: 40vh;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: -1;
    }
  }
}

.box-conversational-intro {
  position: relative;
  display: block;
  max-width: 90%;
  margin: 0 auto;

  &.calibrating-messages {
    z-index: 2;
    max-width: 100%;

    @include breakpoint(large) {
      max-width: 66%;
    }
  }

  &.white {
    z-index: 2;
    max-width: 100%;

    @include breakpoint(large) {
      max-width: 66%;
    }

    .conversational-headline,
    p {
      color: white;
    }
  }

  .img-3-arrows {
    position: absolute;
    top: -22px;
    left: -40px;
  }

  .conversational-headline {
    display: block;
    @include font-family(TheinhardtBold);
    font-size: 30px;
    line-height: 30px;
    color: color(blue-accesible);
    margin-bottom: 20px;
    margin-top: 25px;

    @include breakpoint(large) {
      line-height: 45px;
    }

    svg#planning-action-underline-simple {
      display: block;
    }
  }

  p {
    @include font-family(TheinhardtRegular);
    font-size: 22px;
    line-height: 22px;
    line-height: 30px;
    color: color(black);

    @include breakpoint(large){
      font-size: 30px;
      line-height: 65px;
    }

    .arrows {
      position: relative;
      display: inline-block;

      svg {
        display: none;
        position: absolute;
        top: -15px;
        left: 0;
        max-width: 100%;
        @include breakpoint(large){
          display: block;
        }
      }
    }

    .underline {
      position: relative;
      display: inline-block;

      &.black {
        svg {
          bottom: 0px;
        }

        &.fit {
          svg {
            bottom: 10px;
          }
        }
      }

      svg {
        display: none;
        position: absolute;
        bottom: -5px;
        left: 0;
        max-width: 100%;
        @include breakpoint(large){
          display: block;
        }
      }
    }

    strong {
      position: relative;
      @include font-family(TheinhardtBold);
      font-weight: normal;
      color: color(blue-accesible);

      svg {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        @include breakpoint(large){
          display: block;
        }
      }
    }
  }
}


// Icerberg container
.iceberg-container {
  display: block;
  margin-top: 2px;
  position: relative;

  @include breakpoint(large) {
    top: -174px;
  }

  .iceberg-section {
    // border: 1px solid red;
    display: block;
    position: relative;
    z-index: 10;
    text-align: center;
    margin-top: -1px;

    &.mobile-gradient {
      &:before {
        content: "";
        display: block;
        height: 50px;
        width: 100%;
        position: absolute;
        background: linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 100%);
        top: -50px;
        left: 0;

        @include breakpoint(large) {
          display: none;
        }
      }
    }

    // Section 1
    &.section-1 {
      svg.iceberg-main-shape {
        width: 220px;
        transform: translateX(-17px);

        @include breakpoint(large) {
          width: auto;
          transform: translateX(-30px);
        }
      }

      #section-1-globe-1 {
        top: -10px;
        left: 50%;
        transform: translateX(-450px);

        span {
          top: 40%;
        }
      }

      #section-1-globe-2 {
        top: -10px;
        left: 61%;

        span {
          padding-left: 70px;
        }
      }
    }

    // Section 2
    &#section-2 {

      svg.iceberg-main-shape {
        max-width: 324px;

        @include breakpoint(large) {
          max-width: 100%;
        }
      }

      #section-2-globe-1 {
        top: 5px;
        left: 50%;
        transform: translateX(-615px);
      }
      #section-2-globe-2 {
        top: -17px;
        left: 50%;
        transform: translateX(226px);
      }
      #section-2-globe-3 {
        bottom: 0;
        left: 50%;
        transform: translateX(275px);
      }
    }

    // Section 3
    &#section-3 {
      svg.iceberg-main-shape {
        width: 307px;
        transform: translateX(1px);

        @include breakpoint(large) {
          width: auto;
          transform: translateX(3px);
        }
      }

      #section-3-globe-1 {
        top: -15px;
        left: 50%;
        transform: translateX(-600px);
        span {
          padding-left: 10px;
        }
      }
      #section-3-globe-2 {
        top: 125px;
        left: 50%;
        transform: translateX(-650px);
        span {
          padding-left: 0;
        }
      }
      #section-3-globe-3 {
        top: 50px;
        left: 50%;
        transform: translateX(180px);
        span {
          padding-left: 94px;
        }
      }
    }

    // Section 4
    &#section-4 {
      svg.iceberg-main-shape {
        width: 172px;
        transform: translateX(6px);
        @include breakpoint(large) {
          width: auto;
          transform: translateX(13px);
        }
      }
      .iceberg-headline {
        transform: translate(-60%, -130%);
      }

      #section-4-globe-1 {
        top: 35px;
        left: 50%;
        transform: translateX(-485px);
        span {
          padding-left: 10px;
          padding-top: 10px;
        }
      }
      #section-4-globe-2 {
        top: 52px;
        left: 50%;
        transform: translateX(70px);
        span {
          padding-left: 35px;
          padding-top: 10px;
        }
      }
    }

    .iceberg-headline {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-60%, -30%);
      @include font-family(TheinhardtRegular);
      font-size: 22px;
      line-height: 22px;
      color: white;
      text-align: center;
      @include breakpoint(large) {
        font-size: 35px;
        line-height: 35px;
      }
    }

    .iceberg-globe {
      display: none;
      position: absolute;
      width: min-content;

      @include breakpoint(xlarge) {
        display: block;
      }

      span {
        position: absolute;
        width: 100%;
        z-index: 7;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include font-family(TheinhardtMedium);
        font-size: 22px;
        line-height: 24px;
        color: color(black);
        letter-spacing: 0;
        text-align: center;

        top: 50%;
        text-align: left;
        width: 85%;
        padding-left: 31px;

        &.white {
          color: white;
        }
      }
    }

    svg.iceberg-main-shape {
      position: relative;
      display: block;
      margin: 0 auto;
      z-index: 9;
      max-width: 100%;
      height: auto;
    }
  }
}

// Mobile Iceberg Summary

.iceberg-summary-mobile {
  @include breakpoint(xlarge) {
    display: none;
  }

  &--section {
    display: block;
    padding: 50px 0 0 0;

    .iceberg-summary-headline {
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 30px;
      line-height: 30px;
      color: white;
      margin-bottom: 30px;

      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        @include font-family(TheinhardtRegular);
        font-size: 18px;
        line-height: 28px;
        color: white;
        margin-bottom: 25px;
        padding-left: 35px;
        background: url('/src/images/equity-frameworks/iceberg-mobile-arrow.svg') top 5px left no-repeat;
      }
    }
  }
}


// Icerberg Example

.iceberg-example {
  position: relative;
  overflow: hidden;

  .img-police {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    @include breakpoint(large) {
      width: auto;
    }
  }

  .img-iceberg {
    display: none;
    position: absolute;
    z-index: 2;
    top: 730px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @include breakpoint(large) {
      display: block;
    }
  }

  .background {
    max-width: 1440px;
    margin: 0px auto;
    // border: 1px solid red;
  }

  &--header {
    position: relative;
    width: 90%;
    z-index: 3;
    display: block;
    margin: 0 auto;
    top: 25px;
    text-align: center;
    @include font-family(PlantinMTProLightItalic);
    font-size: 40px;
    line-height: 40px;
    color: white;
    letter-spacing: -2.2px;

    @include breakpoint(large) {
      max-width: 515px;
      font-size: 50px;
      line-height: 45px;
    }

    .block-content {
      position: absolute;
      width: 100%;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      position: relative;
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 22px;
      line-height: 22px;
      letter-spacing: -0.85px;
      margin-bottom: 20px;

      @include breakpoint(large) {
        font-size: 30px;
        line-height: 30px;
      }
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &--section {
    position: relative;
    padding: 50px 0;
    z-index: 2;

    @include breakpoint(large) {
      padding: 125px 0;
      max-width: 1440px;
      margin: 0 auto;
      // border: 1px solid red;
    }

    @include breakpoint(xlarge) {
      padding: 125px 0 225px 0;
    }

    .row {
      max-width: 1040px;
    }

    svg {
      max-width: 100%;
    }

    &.first {
      margin-top: 100px;
      background: url('/src/images/equity-frameworks/bg-iceberg-section-1.png') bottom center no-repeat;
      background-size: contain;

      @include breakpoint(large) {
        margin-top: 0px;
        background: none;
      }
    }

    &.second {
      background-color: #172D4C;
      @include breakpoint(large) {
        background-color: transparent;
      }
    }

    &.third {
      background-color: #182942;
      @include breakpoint(large) {
        background-color: transparent;
      }
    }

    &.fourth {
      background: url('/src/images/equity-frameworks/bg-iceberg-section-4.png') top center no-repeat;
      background-size: contain;

      @include breakpoint(large) {
        background: none;
      }
    }

    .ico-example-arrow {
      display: none;
      margin-bottom: -60px;

      @include breakpoint(large) {
        display: block;
      }
    }

    .example-headline {
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 30px;
      line-height: 30px;
      color: white;
      margin-bottom: 40px;

      @include breakpoint(large) {
        font-size: 75px;
        line-height: 60px;
        letter-spacing: -3.3px;
      }

      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;

        @include breakpoint(large) {
          display: none;
        }
      }
    }

    .example-subheadline {
      display: block;
      @include font-family(PlantinMTProLight);
      font-size: 22px;
      line-height: 24px;
      color: white;
      letter-spacing: -0.5px;
      margin-bottom: 30px;

      @include breakpoint(large) {
        font-size: 30px;
        line-height: 50px;
        margin-bottom: 40px;
        max-width: 850px;
      }

      @include breakpoint(xlarge) {
        font-size: 45px;
      }
    }

    p {
      @include font-family(TheinhardtRegular);
      font-size: 18px;
      line-height: 26px;
      color: white;
      margin-bottom: 20px;

      @include breakpoint(large) {
        font-size: 20px;
        line-height: 40px;
        margin-bottom: 25px;
        margin-left: 150px;
        margin-right: 150px;
      }

      @include breakpoint(xlarge) {
        font-size: 30px;
        line-height: 65px;
        margin-right: 0;
        margin-left: 100px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Button
.button-frameworks {
  display: table;
  @include font-family(TheinhardtMedium);
  font-size: 25px;
  color: white;
  letter-spacing: -0.71px;
  background: color(black);
  border-radius: 29px;
  padding: 7px 20px;

  @include breakpoint(large) {
    font-size: 30px;
    line-height: 30px;
    padding: 10px 30px;
  }

  &.systemic-stories-margins {
    margin-top: 30px;
    margin-bottom: 100px;
    @include breakpoint(large) {
      margin-top: 60px;
      margin-bottom: 130px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: color(blue);
    background: #ffffff;
  }
}

.continue-exploring {
  display: block;
  @include font-family(TheinhardtMedium);
  font-size: 15px;
  line-height: 26px;
  color: #FFFFFF;
  letter-spacing: 0.8px;
  padding-top: 10px;
  border-top: 2px solid white;
  margin-bottom: 20px;

  @include breakpoint(large) {
    line-height: 16px;
    margin-bottom: 50px;
  }

  &.margin-top {
    margin-top: 50px;
  }

  &.black {
    border-color: #1A1C24;
    color: #1A1C24;
  }
}


.mobile-section-nav {
  position: relative;
  overflow: hidden;
  background-color: color(black);
  //height: 520px;
}

// Opportunities Nav
.opportunities-nav {
  position: absolute;
  left: 1.5625rem;
  padding-top: 10%;
  z-index: 20;

  @include breakpoint(medium) {
    max-width: 500px;
    left: 0;
  }

  @include breakpoint(large) {
    left: 1.5625rem;
  }

  .what-we-say {
    display: table;
    @include font-family(TheinhardtMedium);
    font-size: 20px;
    line-height: 25px;
    color: white;
    border-bottom: 2px solid white;

    @include breakpoint(large) {
      font-size: 30px;
      line-height: 45px;
      border-bottom: 3px solid white;
    }
  }

  &.mobile-version {
    padding-top: 0;
    top: 50%;
    transform: translateY(-50%);

    ul li.active svg {
      display: block;
    }
  }

  ul {
    margin: 40px 0 0 0;
    padding: 0;
    list-style: none;

    @include breakpoint(medium) {
      margin-top: 120px;
    }

    li {
      display: block;
      position: relative;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }

      &.active {
        // a {
        //   opacity: 1;
        // }

        svg {
          display: block;
        }
      }

      svg {
        display: none;
        position: absolute;
        left: -20px;
        top: 0px;
        z-index: 1;
        height: auto;

        @include breakpoint(medium) {
          display: block;
          max-width: 100%!important;
        }
      }

      &:nth-child(1) {
        svg {
          max-width: 100px;
          top: 0;
          left: -7px;
          @include breakpoint(medium) {
            top: -20px;
            left: -20px;
          }
        }
      }

      &:nth-child(2) {
        svg {
          max-width: 210px;
          left: -7px;
          top: -13px;
          @include breakpoint(medium) {
            top: -25px;
            left: -27px;
            max-width: 310px!important;
          }
        }
      }

      &:nth-child(3) {
        svg {
          max-width: 255px;
          left: -5px;
          top: -15px;
          @include breakpoint(medium) {
            top: 0px;
            left: -18px;
          }
        }
      }

      &:nth-child(4) {
        svg {
          max-width: 140px;
          top: -7px;
          left: -6px;
          @include breakpoint(medium) {
            top: -30px;
            left: -15px;
          }
        }
      }

      &:nth-child(5) {
        svg {
          max-width: 280px;
          top: -24px;
          left: -7px;

          @include breakpoint(medium) {
            top: -12px;
            left: -22px;
          }
        }
      }

      a {
        position: relative;
        z-index: 30;
        display: block;
        @include font-family(TheinhardtMedium);
        font-size: 30px;
        line-height: 30px;
        color: white;
        letter-spacing: -1.06px;

        @include breakpoint(medium) {
          font-size: 66px;
          line-height: 55px;
          letter-spacing: -5px;
        }

        @include breakpoint(large) {
          font-size: 70px;
          line-height: 55px;
          letter-spacing: -4px;
        }
      }
    }
  }
}

// Opportunity secion
.equity-opportunity-section {
  display: block;
  background-color: color(black);
  position: relative;
  z-index: 10;

  .pinned-mobile-content {
    z-index: 100;
  }

  .image-section {
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .block-content {
    display: block;
    padding-right: 1.5625rem;
    padding-left: 1.5625rem;

    @include breakpoint(large) {
      padding-right: 110px;
      padding-left: 110px;
    }

    &.no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.questions-content {
      padding-bottom: 0!important;
      margin-bottom: -26px;
    }

    &.answers-content {
      display: none;
      position: relative;
      z-index: 0;
      //margin-top: -26px;
      //padding-top: 60px!important;

      ul {
        margin-top: 30px;
      }
    }

    &.end {
      @include breakpoint(large) {
        padding-top: 100px!important;
        padding-bottom: 100px!important;
      }
    }

    &.blue {
      background-color: color(blue-accesible);
      padding-top: 50px;
      padding-bottom: 50px;

      @include breakpoint(large) {
        padding-top: 225px;
        padding-bottom: 225px;
      }

      .headline,
      p {
        color: white;
      }
    }

    &.white {
      background-color: white;
      padding-top: 50px;
      padding-bottom: 50px;

      @include breakpoint(large) {
        padding-top: 225px;
        padding-bottom: 225px;
      }

      .headline,
      p {
        color: color(black);
        border-color: color(black);
      }
    }

    &.black {
      background-color: color(black);
      padding-top: 30px;
      padding-bottom: 30px;

      .headline,
      p {
        color: white;
        border-color: white;
      }
    }

    .block-icon {
      display: block;
      margin-bottom: 30px;
    }

    .headline {
      display: table;
      @include font-family(TheinhardtMedium);
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 50px;
      border-bottom: 2px solid;

      @include breakpoint(large) {
        font-size: 30px;
        margin-bottom: 90px;
      }

      &.opportunities-to-act {
        z-index: 101;
      }
    }

    .subheadline {
      display: block;
      @include font-family(TheinhardtMedium);
      font-size: 15px;
      color: white;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    p {
      font-size: 26px;
      line-height: 30px;

      @include breakpoint(large) {
        font-size: 30px;
        line-height: 50px;
      }

      strong, b {
        font-weight: inherit;
        line-height: inherit;
      }

      &.platin {
        @include font-family(PlantinMTProLight);

        strong {
          @include font-family(TheinhardtMedium);
        }
      }

      &.theinhardt {
        @include font-family(TheinhardtRegular);

        strong {
          display: block;
          @include font-family(TheinhardtMedium);
          margin-bottom: 20px;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      @include breakpoint(large) {
        margin-left: -40px;
        columns: 2;
      }

      li {
        display: block;
        padding-left: 40px;
        @include font-family(TheinhardtRegular);
        font-size: 18px;
        line-height: 26px;
        color: white;
        background: url('/src/images/equity-frameworks/iceberg-mobile-arrow.svg') top 5px left no-repeat;
        margin-bottom: 30px;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

  }
}


.equity-representation-label {
  height: 30px;
  overflow: hidden;
  position: relative;
  display: block;
  @include font-family(TheinhardtMedium);
  color: color(black);
  letter-spacing: -0.54px;
  font-size: 30px;
  line-height: 30px;
  margin-top: 10px;

  @include breakpoint(medium) {
    font-size: 22px;
    margin-top: 0;
  }

  @include breakpoint(large) {
    font-size: 32px;
    margin-top: 0;
  }

  &--underline {
    display: block;
    width: 110px;
    height: 2px;
    background-color: color(blue-accesible);
    margin-bottom: 30px;

    &.empowering {
      width: 170px;
    }
  }

  #harmful-representation-label,
  #respectful-representation-label {
    transform: translateY(30px);
  }

  &.white {
    color: white;
  }
}

.ico-representation {
  width: 42px;
  height: 42px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  opacity: .3;

  @include breakpoint(large) {
    display: block;
    margin-bottom: 20px;
  }

  &.active {
    opacity: 1;
  }

  svg {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.ico-verbs {
  width: 42px;
  height: 42px;
  display: none;
  opacity: .3;
  margin-top: 20px;
  cursor: pointer;

  @include breakpoint(large) {
    display: block;
  }
}

.equity-verbs-label {
  overflow: hidden;
  display: block;
  height: 35px;
  border-bottom: 2px solid color(blue-accesible);
  margin-top: 20px;

  @include breakpoint(medium) {
    margin-top: 0;
  }

  @include breakpoint(large) {
    margin-top: -3px;
  }

  &.white {
    span {
      color: white;
    }
  }

  #harmful-representation-verbs,
  #respectful-representation-verbs {
    transform: translateY(35px);
  }

  span {
    display: block;
    @include font-family(PlantinMTProLightItalic);
    color: color(black);
    letter-spacing: -0.62px;
    font-size: 34px;
    line-height: 37px;
    padding-left: 7px;

    @include breakpoint(medium) {
      font-size: 30px;
      line-height: 37px;
    }

    @include breakpoint(large) {
      font-size: 34px;
      line-height: 37px;
    }
  }
}

.equity-content-description {
  display: block;
  padding-top: 50px;

  @include breakpoint(large) {
    padding-top: 450px;
  }

  &.white {
    .equity-description--headline, p {
      color: white;
    }
  }

  .verbs-block {
    display: block;
    margin-top: 100px;

    @include breakpoint(medium) {
      margin-bottom: 150px;
      margin-top: 0;
    }
  }

  .equity-description--headline {
    display: inline-block;
    position: relative;
    @include font-family(TheinhardtMedium);
    color: color(black);
    letter-spacing: -0.5px;
    font-size: 30px;
    line-height: 22px;
    margin-bottom: 10px;

    span {
      display: block;
      width: 0%;
      height: 2px;
      margin-top: 5px;
      background-color: color(blue-accesible);
    }
  }

  p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 50px;
  }
}

.representation-pinned {
  background-color: white;
  z-index: 100;

  &.black {
    background-color: color(black);
  }
}

.equity_hidden_iframe,
.year_in_review_hidden_iframe {
  position: absolute;
  height: 1px;
  visibility: hidden;
}

.btn-questions {
  display: table;
  border-radius: 26px;
  background-color: white;
  @include font-family(TheinhardtMedium);
  font-size: 15px;
  line-height: 1;
  color: color(black);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 19px 20px;
  margin-top: 30px;
  position: relative;
  z-index: 10;

  &:hover,
  &:active,
  &:focus {
    background-color: color(black);
    color: white;
  }

  &.active {
    background-color: white;
    color: color(black);

    i {
      transform: rotate(180deg);
    }
  }

  i {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 10px;
    margin-right: 5px;

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
